
import { Component, Vue } from "vue-property-decorator"
import moment from "moment"

@Component({
  name: "TheTimelineTitle"
})
export default class TheTimelineTitle extends Vue {
  get todayDate() {
    return moment().format("DD MMMM yyyy")
  }
}
