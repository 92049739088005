
import { Component, Prop, Vue } from "vue-property-decorator"

@Component({
  name: "MonthlyFtp"
})
export default class MonthlyFtp extends Vue {
  @Prop(Number) readonly ftp?: number
  @Prop({ default: false }) readonly isFuture?: boolean

  private isHasFtp: any = !!this.ftp && !this.isFuture
}
