
import { Component, Prop, Vue } from "vue-property-decorator"
import moment from "moment"
import { TimelineItemType } from "@/views/timeline/types"

@Component({
  name: "TimelineEvent"
})
export default class TimelineEvent extends Vue {
  @Prop(String) dateStart!: string
  @Prop(String) name!: string
  @Prop(String) type!: TimelineItemType.Activity | TimelineItemType.Race
  @Prop(String) breakthrough!: any
  @Prop(String) breakthroughValue!: any
  @Prop(String) activityId!: any

  get itemDate() {
    return moment(this.dateStart)
      .format("MMM DD")
      .toUpperCase()
  }

  get isRace() {
    return this.type === TimelineItemType.Race
  }
}
