
import { Component, Prop, Vue } from "vue-property-decorator"
import moment from "moment"
import MonthlyFtp from "@/views/timeline/components/MonthlyFtp.vue"
import DayMarker from "@/views/timeline/components/DayMarker.vue"
import TimelinePlan from "@/views/timeline/components/TimelinePlan.vue"
import TimelinePlanGap from "@/views/timeline/components/TimelinePlanGap.vue"
import TimelineItem from "@/views/timeline/components/TimelineItem.vue"
import { Event, Plan } from "@/views/timeline/types"

@Component({
  name: "TimelineMonth",
  components: { MonthlyFtp, DayMarker, TimelineItem, TimelinePlan, TimelinePlanGap }
})
export default class TimelineMonth extends Vue {
  @Prop(String) month!: string
  @Prop(Number) ftp?: number
  @Prop() events!: Event[]
  @Prop() plans!: Plan[]

  get monthName() {
    return this.month.toUpperCase()
  }

  get isCurrent() {
    const currentDate = moment()
    const date = moment(this.month, "MMM YYYY")

    return date.format("MM/YYYY") === currentDate.format("MM/YYYY")
  }
}
