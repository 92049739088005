
import { Component, Vue } from "vue-property-decorator"
import moment from "moment"

@Component({
  name: "DayMarker"
})
export default class DayMarker extends Vue {
  private isMobile = false

  get left() {
    const daysOfMonth = moment().daysInMonth()
    const day = Number(moment().format("DD"))

    const padding = 6
    const paddingRumbles = 10
    const mobileWidth = 172
    const desktopWidth = 264

    const widthMonth = (this.isMobile ? mobileWidth : desktopWidth) - paddingRumbles
    const widthDay = widthMonth / daysOfMonth
    const positionDay = widthDay * day

    return positionDay - padding
  }
}
