<template>
  <div class="timeline-plan-gap"></div>
</template>

<script>
export default {
  name: "TimelinePlanGap"
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.timeline-plan-gap {
  height: $timeline-plan-height-desktop;

  @include media("<desktop") {
    height: $timeline-plan-height-mobile;
  }
}
</style>
