
import { Component, Vue } from "vue-property-decorator"
import MonthlyFtp from "@/views/timeline/components/MonthlyFtp.vue"
import TheTimelineTitle from "@/views/timeline/components/TheTimelineTitle.vue"
import TimelineMonth from "@/views/timeline/components/TimelineMonth.vue"

import { mapActions, mapState } from "vuex"

@Component({
  name: "JackTimeline",
  components: { TheTimelineTitle, MonthlyFtp, TimelineMonth },
  computed: {
    ...mapState("timeline", ["timeline"])
  },
  methods: {
    ...mapActions("timeline", ["getTimelineData", "checkProcess"])
  }
})
export default class JackTimeline extends Vue {
  private getTimelineData!: Function
  private timeline!: []
  private isFinished!: boolean
  private checkProcess!: Function

  data() {
    return {
      isFinished: false
    }
  }
  async mounted() {
    await this.getTimelineData()
    await this.checkProcessInterval()
  }

  async checkProcessInterval() {
    let res
    res = await this.checkProcess()
    if (!res.data) {
      const timerId = setInterval(async () => {
        res = await this.checkProcess()
        if (res.data) {
          clearInterval(timerId)
          this.isFinished = true
        } else {
          this.isFinished = false
        }
      }, 5000)
    } else {
      this.isFinished = true
    }
  }
}
