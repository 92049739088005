
import { Component, Vue } from "vue-property-decorator"
import TimelineHero from "@/views/timeline/components/TimelineHero.vue"
import JackTimeline from "@/views/timeline/components/JackTimeline.vue"

@Component({
  name: "Timeline",
  components: { TimelineHero, JackTimeline }
})
export default class Timeline extends Vue {}
