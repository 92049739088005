export enum TimelineItemType {
  Plan = "Plan",
  Activity = "Activity",
  Race = "Race"
}

export interface Event {
  dateStart: string
  name: string
}

export interface Plan extends Event {
  type: TimelineItemType.Plan
  dateFinish: string
  line: number
}

export interface Activity extends Event {
  type: TimelineItemType.Activity
  comment: string
}

export interface Race extends Event {
  type: TimelineItemType.Race
}

export interface TimelineItemMonth {
  month: string
  ftp?: number
  plans: Plan[]
  events: Event[]
}

export type PlanLevelConfig = {
  level: number
  _id: string
}
