<template>
  <div class="timeline-plan">
    <div class="timeline-plan-container" :style="{ left: left + 'px', width }">
      <div class="timeline-plan-date">{{ start }} - {{ finish }}</div>
      <div class="timeline-plan-title"><span class="timeline-plan-title--plan">PLAN:</span> {{ name }}</div>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator"
import moment from "moment"

const gap = 20
const mobileWidth = 280 // TODO: make it smaller
const desktopWidth = 280

@Component({
  name: "TimelinePlan"
})
export default class TimelinePlan extends Vue {
  @Prop(String) dateStart
  @Prop(String) dateFinish
  @Prop(String) month
  @Prop(String) name

  get start() {
    return moment(this.dateStart)
      .format("MMMM DD")
      .toUpperCase()
  }

  get finish() {
    return moment(this.dateFinish)
      .format("MMMM DD")
      .toUpperCase()
  }

  get left() {
    const daysInMonth = moment(this.dateStart).daysInMonth()
    const day = Number(moment(this.dateStart).format("DD"))

    const widthMonth = this.isMobile ? mobileWidth : desktopWidth
    const widthDay = widthMonth / daysInMonth

    return widthDay * (day - 1)
  }

  get right() {
    const daysInMonth = moment(this.dateFinish).daysInMonth()
    const day = Number(moment(this.dateFinish).format("DD"))

    const widthMonth = this.isMobile ? mobileWidth : desktopWidth
    const widthDay = widthMonth / daysInMonth

    return widthDay * day
  }

  get width() {
    const monthsCount = moment(this.dateFinish)
      .endOf("month")
      .diff(moment(this.dateStart).startOf("month"), "months")
    const monthsGaps = gap * monthsCount
    const monthsWidth = desktopWidth * monthsCount

    return `${monthsWidth + monthsGaps + this.right - this.left}px`
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.timeline-plan {
  &-container {
    border-left: 5px solid $dark-blue;
    border-radius: $timeline-border-radius;
    height: $timeline-plan-height-desktop;
    padding-left: 10px;
    background-color: $light-blue4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }

  @include media("<desktop") {
    max-height: $timeline-plan-height-mobile;
  }

  &-date {
    color: $dark-blue;
    font-size: 12px;
  }

  &-title {
    font-size: 14px;

    &--plan {
      color: $dark-blue;
      font-weight: 500;
    }
  }
}
</style>
